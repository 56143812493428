import React from 'react';
import Card from '../Card/Card'
import { Link } from 'react-router-dom';

const BannerComponent = () => {
    return (
        <section id="banner" className="container-fluid background ">
            <h1 className="text-center my-2 heading">
                Tariffs
            </h1>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Link to="/tarrif-details/nex-gen">
                            <Card
                                url={require('../assets/images/chainless.png')}
                                name="NextGen"
                            >
                            </Card>
                        </Link>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Link to="/tarrif-details/e-bike">
                            <Card
                                url={require('../assets/images/ebike.png')}
                                name="eBike">
                            </Card>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Link to="/tarrif-details/eco-bike">
                            <Card
                                url={require('../assets/images/ecoBike.png')}
                                name="eco Bike">
                            </Card>
                        </Link>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Link to="/tarrif-details/smart-bike">
                            <Card
                                url={require('../assets/images/smartBike.png')}
                                name="Smart Bike">
                            </Card>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerComponent;
