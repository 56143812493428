import React, { Fragment } from 'react';
import {
  Route,
  Routes
} from 'react-router-dom'
import './App.css';

import BannerComponent from './BannerComponent/BannerComponent'
import FeatureComponent from './FeatureComponent/FeatureComponent'
import FooterComponent from './FooterComponent/FooterComponent';

const App = () => {
  return (
    <div>
      <Routes>
        <Fragment>
          <Route path="/" element={ <BannerComponent />}/>
          <Route path="/tarrif-details/:vehicleName" element={ <FeatureComponent />}/>
        </Fragment>
      </Routes>
      {/* <FooterComponent/> */}
    </div>
  );
}

export default App;


