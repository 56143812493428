
import React from 'react'

function Card(props) {
    return (
        <div className="box card m-cust" style={{width : "90%", height : "90%"}}>
            <img className="card-img-top" src={props.url} alt="Card image cap" />
            <div className="card-body">
                <h5 className="card-title text-center text-danger">{props.name}</h5>
            </div>
        </div>
    )
}

export default Card;
