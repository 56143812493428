import React from 'react';
import {useParams} from 'react-router-dom';
import Card from '../Card/Card'

const vehicleDetails = {
    "nex-gen" : {
        imgUrl : require('../assets/images/chainless.png'),
        text : "NexGen"
    },
    "e-bike" : {
        imgUrl : require('../assets/images/ebike.png'),
        text : "e Bike"
    },
    "eco-bike" : {
        imgUrl : require('../assets/images/ecoBike.png'),
        text : "eco Bike"
    },
    "smart-bike" : {
        imgUrl : require('../assets/images/smartBike.png'),
        text : "Smart Bike"
    },
}

 const FeatureComponent = () => {
     const params = useParams();
     const values = vehicleDetails[params.vehicleName]
     const tarrifUrl = 'https://i.ibb.co/258Yyht/SMB-tarrif.png'
     return (
       <section id="features" className="container-fluid pt-5 pb-5">
         <div className="container">
            <div className="row justify-content-center">
               <h3 className="heading">Tarrif Chart</h3>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-12 text-center">
                    <Card
                        url={values.imgUrl}
                        name={values.text}
                    />
                </div>
            </div>
            <div className="row my-4">
            <div className="col-xs-12 col-md-12 text-center">
                <img style={{width:"100%"}} src={tarrifUrl} alt="SMB-tarrif"/>    
                </div>
            </div> 
         </div>
       </section>
    )
 }

 export default FeatureComponent;